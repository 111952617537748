<script setup lang="ts">
const route = useRoute()
const isOpen = ref(false)
const q = ref('')

</script>

<style>
.cross-container {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}

.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 3px;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 9px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -9px) rotate(45deg);
}
</style>
<template>
  <nav class="px-6 py-3 fixed top-0 z-50 bg-opacity-85 bg-[#29321d] w-full text-gray-300">
    <div class="flex justify-between items-center">
      <NuxtLink to="/" class="text-2xl font-bold ">
        <img src="/images/logo.svg" alt="Storm logo" class="logo w-24 md:w-32 lg:w-48 xl:w-48 " />
      </NuxtLink>
      <div class="flex flex-row space-x-7 items-center whitespace-nowrap ml-3 text-xs sm:text-base">
        <!-- <Search class="hidden sm:block"/> -->
        <NuxtLink v-if="false" to="/products" class="align-middle">Products</NuxtLink>
        <span v-if="false">|</span>
        <NuxtLink to="/contact" class="align-middle">Contact</NuxtLink>
        <div class="cross-container align-middle" data-drawer-target="drawer-right-menu" data-drawer-show="drawer-right-menu" data-drawer-placement="right" aria-controls="drawer-right-menu">
          <div class="bar1 bg-gray-300"></div>
          <div class="bar2 bg-gray-300"></div>
          <div class="bar3 bg-gray-300"></div>
        </div>
      </div>
    </div>
  </nav>
  <SideMenu v-model="isOpen" />
</template>
